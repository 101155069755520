.Permission {
    white-space: inherit !important;
}

.Permission a {
    margin: 5px 0px;
    display: inline-block;
    font-size: 12px;
    padding: 2px 10px;
}
.absent_btn {
    background: #ed3a3a;
    color: #fff;
    padding: 5px 20px;
    border-radius: 12px;
    font-weight: 600;
    box-shadow: 0 12px 27px rgba(1, 28, 75, 0.07);
}